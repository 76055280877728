
import { Vue, Component } from "vue-property-decorator";
import Hero from "@/components/common/Hero.vue";
import sBackgroundImg from "@/assets/img/hero/h1.jpg";
import { Article } from "@planetadeleste/vue-mc-goodnews";

@Component({
  components: { Hero },
})
export default class HomeHero extends Vue {
  obArticle: Article = new Article();
  loading = false;

  mounted(): void {
    this.$nextTick(this.loadArticle);
  }

  async loadArticle(): Promise<void> {
    this.loading = true;
    this.obArticle = new Article({ slug: "home" });
    await this.obArticle.fetch();
    this.loading = false;
  }

  get bgImg(): string {
    return this.loading ? "" : this.obArticle.preview_image || sBackgroundImg;
  }
}
